/**
* * Reset
*/
* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/**
* * Body
*/
body {
    font-family: 'Exo 2', sans-serif;
    background-color: #ffffff;  
    font-weight: 400;
    color: #1b1b1b;
    font-size: 1rem;
    min-height: 100vh;

    a {
        text-decoration: none;
        color: #212121;
        transition: .2s;
        letter-spacing: 2px;
        cursor: pointer;
    }

    a:hover {
        color: #009180;
    }

    p {
        font-size: 1.2rem;
        line-height: 34px;
        letter-spacing: 1px;
    }

    li {
        list-style-type: none;
    }

    h1 {
        font-size: 3rem;
        letter-spacing: 3px;
    }

    h2 {
        font-size: 2rem;
        text-transform: uppercase;
        letter-spacing: 3px;
        color: #5f5f5f;
        font-weight: 100;
        position: relative;
        display: inline-block;
    }

    h3 {
        letter-spacing: 1px;
    }
}

@media (max-width: 768px) {
    body {
        h1 {
            font-size: 1.8rem;
        }

        h2 {
            font-size: 1.5rem;
        }

        p {
            font-size: 1rem;
            line-height: 30px;
        }
    }
}

/**
* * Shell
*/
.shell {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

/**
* * Buttons
*/
.btn {
    cursor: pointer;
    transition: .2s;
    padding: 12px 25px;
}

.btn--green {
    background-color: transparent;
    border: 2px solid #009180;
    color: #009180;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 1px;
}

.btn--green:hover {
    background-color: #009180;
    color: #fff;
}

.btn--white {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 1px;
}

.btn--white:hover {
    opacity: .7;
}

/**
* * Grid
*/
.grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin: 0 -20px;
}

.grid .grid__col {
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0 20px;
    justify-content: center;
    margin-top: 70px;
}

.grid .grid__col--1of2 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.grid .grid__col--1of3 {
    max-width: calc(100% / 3);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% / 3);
    flex: 0 0 calc(100% / 3);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.grid .grid__col--1of4 {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.grid-no-gutter {
    margin: 0;
}